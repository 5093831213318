import React from 'react';
import { ModeToggle } from './mode-toggle';

function NavBar() {
	return (
		<header className="p-6">
			<div className="container mx-auto flex justify-between items-center">
				<h1
					className="text-2xl font-bold text-gray-900 dark:text-gray-100"
					onClick={() => {
						window.location.href = '/';
					}}
					style={{ cursor: 'pointer' }}
				>
					tinystory
				</h1>

				<ModeToggle />
			</div>
		</header>
	);
}

export default NavBar;
