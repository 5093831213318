import React from 'react';

function Footer() {
	return (
		<footer className="p-6 bottom-0 mt-60 w-full">
			<div className="container mx-auto flex justify-between items-center">
				<div className="flex flex-col">
					<h1 className="text-sm font-bold text-gray-900 dark:text-gray-100">
						tinystory
					</h1>

					<h6 className="text-xs text-gray-900 dark:text-gray-100">
						by tinyneural.com
					</h6>
				</div>
				<div className="flex space-x-4">
					<a
						href="/privacy-policy"
						className="text-xs text-gray-900 dark:text-gray-100"
					>
						Privacy
					</a>
					<a
						href="/terms-and-conditions"
						className="text-xs text-gray-900 dark:text-gray-100"
					>
						Terms
					</a>
				</div>
			</div>
		</footer>
	);
}

export default Footer;
