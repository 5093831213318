import React, { useRef, useState } from 'react';
import Markdown from 'react-markdown';
import html2canvas from 'html2canvas';
import { Download } from 'lucide-react';
import { CardContent } from './components/ui/card';
import { Button } from './components/ui/button';
import { useToast } from './components/ui/use-toast';

function StoryViewer({ content }) {
	const componentToDownloadRef = useRef(null);
	const [downloadingStory, setDownloadingStory] = useState(false);
	const { toast } = useToast();

	const createWatermark = (width, height, text) => {
		const canvas = document.createElement('canvas');
		canvas.width = width;
		canvas.height = height;
		const ctx = canvas.getContext('2d');
		const watermarkHeight = 200; // Height of the watermark bar

		ctx.fillStyle = 'rgba(255, 255, 255, 1.0)';
		ctx.fillRect(
			0,
			canvas.height - watermarkHeight,
			canvas.width,
			watermarkHeight
		);

		ctx.font = 'bold 60px Arial';
		ctx.fillStyle = 'rgba(255, 0, 0, 1.0)';
		ctx.textAlign = 'center';
		ctx.textBaseline = 'middle';
		ctx.fillText(
			text,
			canvas.width / 2,
			canvas.height - watermarkHeight / 2
		);

		return canvas;
	};

	const composeImages = (baseCanvas, watermarkCanvas) => {
		const resultCanvas = document.createElement('canvas');
		resultCanvas.width = baseCanvas.width;
		resultCanvas.height = baseCanvas.height;
		const ctx = resultCanvas.getContext('2d');

		// Draw base image
		ctx.drawImage(baseCanvas, 0, 0);

		ctx.globalAlpha = 1.0;
		ctx.drawImage(
			watermarkCanvas,
			0,
			0,
			baseCanvas.width,
			baseCanvas.height
		);

		return resultCanvas;
	};
	const generateUniqueFilename = () => {
		const timestamp = Math.floor(Date.now() / 1000).toString(36);
		const randomString = Math.random().toString(36).substring(2, 6);
		return `tinystory_app_${timestamp}${randomString}.png`;
	};

	const download = async () => {
		if (componentToDownloadRef.current) {
			setDownloadingStory(true);
			try {
				const canvas = await html2canvas(
					componentToDownloadRef.current
				);

				const watermarkCanvas = createWatermark(
					canvas.width,
					canvas.height,
					'tinystory.app'
				);
				const finalCanvas = composeImages(canvas, watermarkCanvas);
				const imageDataUrl = finalCanvas.toDataURL('image/png');
				const link = document.createElement('a');
				link.href = imageDataUrl;
				link.download = generateUniqueFilename();

				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				setDownloadingStory(false);
			} catch (error) {
				toast({
					title: 'Error',
					message: 'Failed to download the story',
					type: 'destructive',
				});
				setDownloadingStory(false);
			}
		}
	};

	return (
		<>
			<CardContent
				className="space-y-6 pt-6"
				ref={componentToDownloadRef}
			>
				<Markdown>{content}</Markdown>
				<div className="flex justify-center">
					<Button
						onClick={() => download()}
						variant="outline"
						size="sm"
					>
						<Download className="mr-2 h-4 w-4" /> Save as Image
					</Button>
				</div>
			</CardContent>
		</>
	);
}

export default StoryViewer;
