import { Route, Routes } from 'react-router-dom';
import Layout from './Layout';
import StoryGenInterface from './StoryGenInterface';
import TermsConditions from './TermsConditions';
import Privacy from './Privacy';

function App() {
	return (
		<>
			<Routes>
				<Route path="/" element={<Layout />}>
					<Route index element={<StoryGenInterface />} />
					<Route
						path="terms-and-conditions"
						element={<TermsConditions />}
					/>
					<Route path="privacy-policy" element={<Privacy />} />
				</Route>
			</Routes>
		</>
	);
}

export default App;
