import React, { useState } from 'react';
import { PenLine, Loader2 } from 'lucide-react';
import { Button } from './components/ui/button';
import { Card, CardContent, CardFooter } from './components/ui/card';
import { Label } from './components/ui/label';
import {
	Select,
	SelectTrigger,
	SelectContent,
	SelectItem,
	SelectValue,
} from './components/ui/select';
import { RadioGroup, RadioGroupItem } from './components/ui/radio-group';
import { Input } from './components/ui/input';
import { Skeleton } from './components/ui/skeleton';
import { useToast } from './components/ui/use-toast';

import StoryViewer from './StoryViewer';

import { generate_story } from './api_calls';

const story_categories = [
	'Bedtime',
	'Adventure',
	'Sci-Fi',
	'Fantasy',
	'Mystery',
	'Romance',
	'Horror',
];

const story_lengths = ['Short', 'Medium', 'Long'];
const story_moods = ['Happy', 'Sad', 'Neutral'];
const story_voices = ['Male', 'Female', 'Robot'];

function StoryGenInterface() {
	const { toast } = useToast();

	const [category, setCategory] = useState('Bedtime');
	const [mood, setMood] = useState('Happy');
	const [voice, setVoice] = useState('Female');
	const [storyLength, setStoryLength] = useState('Short');
	const [randomStory, setRandomStory] = useState(true);
	const [storyContext, setStoryContext] = useState('');
	const [error, setError] = useState(null);
	const [generatingStory, setGeneratingStory] = useState(false);
	const [storyGenerated, setStoryGenerated] = useState(false);
	const [storyContent, setStoryContent] = useState('');

	const resetConfigs = () => {
		setCategory('Bedtime');
		setMood('Happy');
		setVoice('Female');
		setStoryLength('Short');
		setRandomStory(true);
		setStoryContext('');
		setError(null);
		setGeneratingStory(false);
		setStoryContent('');
		setStoryGenerated(false);
	};

	const createStory = () => {
		setError(null);
		if (!randomStory && storyContext === '') {
			setError('Please provide a context for the story');
			return;
		}

		let formData = new FormData();
		formData.append('category', category);
		formData.append('mood', mood);
		formData.append('length', storyLength);
		formData.append('voice', voice);
		formData.append('surprise_me', randomStory);
		formData.append('story_context', storyContext);

		setGeneratingStory(true);

		generate_story(formData)
			.then((response) => {
				setStoryContent(response.data['content']);
				setGeneratingStory(false);
				setStoryGenerated(true);
			})
			.catch((error) => {
				setGeneratingStory(false);
				setStoryGenerated(false);
				toast({
					title: 'Error',
					message: error.response.data['error'],
					variant: 'destructive',
				});
			});
	};

	return (
		<div className={`flex justify-center p-4`}>
			<Card className={`w-full max-w-lg  shadow-xl`}>
				{generatingStory ? (
					<CardContent className="space-y-6 pt-6">
						<div className="flex justify-center">
							<h3 className="text-xl font-semibold">
								Creating Story...
							</h3>
						</div>
						<div className="flex flex-col space-y-3">
							<div className="space-y-2">
								<Skeleton className="h-4 w-[250px]" />
								<Skeleton className="h-4 w-[200px]" />
							</div>
						</div>
					</CardContent>
				) : (
					<>
						{storyGenerated ? (
							<StoryViewer content={storyContent} />
						) : (
							<CardContent className="space-y-6 pt-6">
								<div className="space-y-2">
									<Label htmlFor="category">
										What do you want to read?
									</Label>
									<Select onValueChange={setCategory}>
										<SelectTrigger id="category">
											<SelectValue
												placeholder={category}
											/>
										</SelectTrigger>
										<SelectContent>
											{story_categories.map(
												(_category) => (
													<SelectItem
														key={_category}
														value={_category}
													>
														{_category}
													</SelectItem>
												)
											)}
										</SelectContent>
									</Select>
								</div>

								{/* <div className="space-y-2">
                                    <Label htmlFor="voice">Voice</Label>
                                    <Select onValueChange={setVoice}>
                                        <SelectTrigger id="voice">
                                            <SelectValue placeholder={voice} />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {story_voices.map((_voice) => (
                                                <SelectItem key={_voice} value={_voice}>
                                                    {_voice}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </div> */}

								<div className="space-y-2">
									<Label>Mood</Label>
									<div className="flex space-x-4">
										{story_moods.map((_mood) => (
											<Button
												key={_mood}
												variant={
													mood === _mood
														? 'default'
														: 'outline'
												}
												onClick={() => setMood(_mood)}
												size="sm"
											>
												{_mood}
											</Button>
										))}
									</div>
								</div>
								<div className="space-y-2">
									<Label>Length</Label>
									<div className="flex space-x-4">
										{story_lengths.map((_length) => (
											<Button
												key={_length}
												variant={
													storyLength === _length
														? 'default'
														: 'outline'
												}
												onClick={() =>
													setStoryLength(_length)
												}
												size="sm"
											>
												{_length}
											</Button>
										))}
									</div>
								</div>
								<div className="space-y-2">
									<Label>Context</Label>
									<div className="flex space-x-4">
										<RadioGroup
											value={
												randomStory
													? 'random'
													: 'custom'
											}
											onValueChange={(value) => {
												setRandomStory(
													value === 'random'
												);
												{
													value === 'random' &&
														setError(null);
												}
											}}
										>
											<div className="flex items-center space-x-2">
												<RadioGroupItem
													value="random"
													id="random-context"
												/>
												<Label htmlFor="random-context">
													Surprise me!
												</Label>
											</div>
											<div className="flex items-center space-x-2">
												<RadioGroupItem
													value="custom"
													id="custom-context"
												/>
												<Label htmlFor="custom-context">
													I'll provide one
												</Label>
											</div>
										</RadioGroup>
									</div>

									{!randomStory && (
										<div>
											<Input
												placeholder="monkeys chasing banana"
												maxLength={200}
												type="text"
												onChange={(e) =>
													setStoryContext(
														e.target.value
													)
												}
											/>
										</div>
									)}
								</div>
							</CardContent>
						)}
					</>
				)}

				<CardFooter className="pt-4">
					{storyGenerated ? (
						<Button
							onClick={resetConfigs}
							className="w-full"
							variant="secondary"
						>
							<PenLine className="h-6 w-6 mr-2" />
							Create Another Story
						</Button>
					) : (
						<Button
							onClick={createStory}
							// className="w-full bg-purple-600 hover:bg-purple-700 text-white"
							className="w-full bg-orange-400 hover:bg-orange-700 text-white"
							variant="default"
							disabled={generatingStory}
						>
							{generatingStory ? (
								<Loader2 className="mr-2 h-4 w-4 animate-spin" />
							) : (
								<>
									<PenLine className="h-6 w-6 mr-2" />
									Create
								</>
							)}
						</Button>
					)}
				</CardFooter>

				{error && (
					<p className="text-red-500 text-sm text-center pb-6">
						{error}
					</p>
				)}
			</Card>
		</div>
	);
}

export default StoryGenInterface;
