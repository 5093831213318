import axios from 'axios';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';

// @ts-ignore
if (document.cookie.match(/csrftoken=(\S+)/)?.length > 0) {
	axios.defaults.headers.common['X-CSRFTOKEN'] =
		// @ts-ignore
		document.cookie.match(/csrftoken=(\S+)/)[1];
}

const api_client = axios.create({ baseURL: process.env.REACT_APP_API_URL });

export const generate_story = async (formData) => {
	return await api_client.post('/stories/', formData, {
		withCredentials: true,
	});
};
